import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Helmet } from 'react-helmet'
import Cookies from 'js-cookie'

import AuthLayout from 'layouts/auth'
import Seo from 'components/seo'
import SimpleStepper from 'components/stepper/simple'

import { getLoginAsUrl, sendDataLayerEvent } from 'utils'

const StartTrialPage = () => {
  const jwtToken = Cookies.get('jwtToken')

  const customerId = Cookies.get('customerId')
  const organizationId = Cookies.get('organizationId')

  const { t } = useTranslation()
  const [countDownSecond, setCountDownSecond] = useState(3)

  useEffect(() => {
    const delay = 1000 // 每秒更新一次倒數

    const countdownInterval = setInterval(() => {
      if (countDownSecond >= 1) {
        setCountDownSecond(countDownSecond - 1)
      } else {
        clearInterval(countdownInterval)

        const eventPayload = {
          event: 'start_trial'
        }

        sendDataLayerEvent(eventPayload)

        window.location.href = getLoginAsUrl(customerId, organizationId, jwtToken)
      }
    }, delay)

    return () => {
      clearInterval(countdownInterval)
    }
  }, [countDownSecond])

  const steps = ['onBoardingSteps.0', 'onBoardingSteps.1', 'onBoardingSteps.2']

  return (
    <AuthLayout hasAccountControl={false}>
      {jwtToken ? (
        <React.Fragment>
          <Seo
            seo={{
              metaTitle: 'Start trial | Mlytics',
              metaDescription: 'Start trial with Mlytics',
              metaUrl: 'https://www.mlytics.com/start-trial/',
              shareImage: {
                imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
                imageWidth: 1200,
                imageHeight: 630,
                imageType: 'image/jpeg'
              }
            }}
          />

          <div className="container">
            <SimpleStepper
              steps={steps}
              className="py-10 min-w-fit max-w-[600px] mx-auto"
              currentStepIndex={2}
              orientation="horizontal"
            />
            <section className="xl:px-20 xl:py-5 text-center">
              <h1 className="mb-4 text-3.5xl text-blue-300">{t('startTrialPage.title')}</h1>
              <p className="text-gray-800">{t('startTrialPage.countDownText', { count: countDownSecond })}</p>
              <Helmet>
                <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
              </Helmet>
              <lottie-player
                src="https://assets9.lottiefiles.com/packages/lf20_azti1dok.json"
                speed="1"
                style={{ margin: '0 auto', width: '100%', height: 'auto', maxWidth: '600px', maxWeight: '450px' }}
                loop
                autoplay
              ></lottie-player>
            </section>
          </div>
        </React.Fragment>
      ) : (
        ''
      )}
    </AuthLayout>
  )
}

export default StartTrialPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
