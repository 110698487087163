import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import { Stepper, Step, StepLabel } from '@mui/material'

const Simple = ({ className = '', currentStepIndex, steps, orientation = 'horizontal' }) => {
  const { t } = useTranslation()

  const { screenM } = useBreakpoint()

  return (
    <Stepper
      className={className}
      activeStep={currentStepIndex}
      orientation={orientation}
      alternativeLabel={screenM ? false : true}
    >
      {steps.map((label, index) => (
        <Step key={label}>
          <StepLabel
            sx={{
              '& .MuiStepLabel-label.Mui-completed': {
                color: '#3B3B3B',
                fontWeight: '700'
              },
              '& .MuiStepLabel-label.Mui-active': {
                color: '#3B3B3B',
                fontWeight: '700'
              },
              '& .MuiStepLabel-label.Mui-disabled': {
                color: '#595959',
                fontWeight: '400'
              }
            }}
            StepIconProps={{ style: { color: currentStepIndex >= index ? '#21838D' : '#A8A8A8' } }}
          >
            {t(label)}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

Simple.propTypes = {
  className: PropTypes.string,
  currentStepIndex: PropTypes.number,
  steps: PropTypes.array,
  orientation: PropTypes.string
}

export default Simple
